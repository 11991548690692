import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { UserMetaEntity, UserMetaService } from '@fi/app/client';
import { IJwtPayload } from '@fi/app/core';
import { AuthServices } from '@fi/app/core/services/auth.services';
import { UsersService } from '@fi/app/core/services/users.service';
@Component({
  selector: 'profile-additional-info',
  template: `<form [formGroup]="form" novalidate (submit)="form.valid && save()">
    <div class="content">
      <utenti-additional-data-form [parentForm]="form"></utenti-additional-data-form>
      <div class="row mt-4">
        <div class="col-sm-12 text-end">
          <button type="submit" class="btn btn-secondary rounded">Salva</button>
        </div>
      </div>
    </div>
  </form>`,
})
export class ProfileAdditionalInfoComponent implements OnInit {
  form = this.fb.group({});
  user: IJwtPayload = null;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthServices,
    private usersService: UsersService,
    private userMetaService: UserMetaService,
  ) {}

  ngOnInit(): void {
    this.authService.user().subscribe((res) => {
      this.user = res;

      this.userMetaService.findByFusionUserId(this.user.sub).subscribe((userMeta) => {
        this.form.patchValue(userMeta);
      });
    });
  }

  save() {
    const userForm: UserMetaEntity = this.form.value;
    /* this.usersService.updateUserMeta(this.user.sub, userForm).subscribe(); */
  }
}
